import React, { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';

const Rating = ({ rating }) => {
    const [rate, setRate] = useState(0);

    useEffect(() => {
        setRate(rating);
    }, [rating]);

    return (
        <ul className="rating">
            <Fade cascade>
                {Array.from({ length: 5 }, (_, index) => (
                    <li
                        key={index}
                        className={index < rate ? 'colorstar' : 'whitestar'}
                    >
                        &#9733;
                    </li>
                ))}
            </Fade>
        </ul>
    );
};

export default Rating;
