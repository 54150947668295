import React from 'react';
import LogementService from '@/_Services/Service.logement';
import Slideshow from '@/Components/Slideshow/Slideshow';
import Host from '@/Components/Host/Host';
import Rating from '@/Components/Rating/Rating';
import Tag from '@/Components/Tag/Tag';
import Collapse from '@/Components/Collapse/Collapse'


const Logement = () => {
    const logement = LogementService.GetOneLogement();


    return (
        <section className='logement'>
            <Slideshow slides={logement.pictures} />
            <div className='properties'>
                <div className='infosAppart'>
                    <h2>{logement.title}</h2>
                    <p>{logement.location}</p>
                    <Tag tags={logement.tags} />
                </div>
                <div className='infosHost'>
                    <Host name={logement.host.name} picture={logement.host.picture} />
                    <Rating rating={logement.rating} />
                </div>
            </div>
            <div className='descriptions'>
                <Collapse title='Description' description={logement.description} />
                <Collapse title='équipement' description={logement.equipments} />
            </div>
        </section >
    );
};

export default Logement;