import React, { useState, useEffect } from 'react';

const Slideshow = ({ slides }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (slides.length > 0) {
            setImages(slides);
        }
    }, [slides]);

    const goToNext = () => {
        setCurrentIndex((index) => (index === images.length - 1 ? 0 : index + 1));
    };

    const goToPrevious = () => {
        setCurrentIndex((index) => (index === 0 ? images.length - 1 : index - 1));
    };

    return (
        <div className="slideshow" style={{ backgroundImage: `url(${images[currentIndex]})` }}>
            <div className="slide">
                <div className="arrows">
                    {images.length > 1 && (
                        <div className="arrowslideleft" onClick={goToPrevious}>
                            &#12296;
                        </div>
                    )}
                    {images.length > 1 && (
                        <div className="arrowslideright" onClick={goToNext}>
                            &#12297;
                        </div>
                    )}
                </div>
                {images.length > 1 && (
                    <ul className="bulletslide">
                        {
                            images.map((image, index) =>
                            (
                                <li key={index} className={index === currentIndex ? "active" : ""} onClick={() => setCurrentIndex(index)} >
                                    {index === currentIndex ? '\u29BF' : '\u29BE'}
                                </li>
                            )
                            )
                        }
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Slideshow;