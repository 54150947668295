import React from 'react';
import { Fade } from 'react-awesome-reveal';

const Tag = ({ tags }) => {
    return (
        <ul className='tags'>
            <Fade cascade>
                {tags.map((item) => (
                    < li className='tag' >{item}</li>
                ))
                }
            </Fade>
        </ul >
    )
};

export default Tag;