import ListeLogements from "@/Assets/Api/Logement.json";


const GetAllLogement = () => {
    return ListeLogements;
}


const GetOneLogement = () => {
    // Récupérer l'ID de l'URL
    const idFromURL = window.location.pathname.split('/').filter(Boolean).pop();
    return ListeLogements.find(logement => logement.id === idFromURL);
}

export const LogementService = {
    GetAllLogement, GetOneLogement
}

export default LogementService;