import AboutDescriptions from "@/Assets/Api/About.json";

let GetAboutDescription = () => {
    return AboutDescriptions;
}


export const AboutService = {
    GetAboutDescription
}

export default AboutService