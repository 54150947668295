import React from 'react';
import { NavLink } from 'react-router-dom';
import LogementService from "@/_Services/Service.logement";
import { Zoom } from "react-awesome-reveal";
import Banner from "@/Components/Banner/Banner";
import Card from "@/Components/Card/Card";

import BannerImg from "@/Assets/Images/Pages/Home/HeroAccueil.png";


const Home = () => {
    return (
        <section className='sectionHome'>

            <Banner image={BannerImg} title="Être d'ici et d'ailleurs" />

            <ul className="listeLogement">
                {
                    LogementService.GetAllLogement().map((logement) =>
                        <Zoom>
                            <NavLink key={logement.id} to={"/logement/" + logement.id + "/#"}>
                                <Card image={logement.cover} title={logement.title} />
                            </NavLink>
                        </Zoom>
                    )
                }
            </ul>
        </section>
    );
};

export default Home;