import React from 'react';
import Banner from '@/Components/Banner/Banner';
import BannerImg from '@/Assets/Images/HeroAbout.png';
import AboutService from '@/_Services/service.about';
import Collapse from '@/Components/Collapse/Collapse';
import { Fade } from 'react-awesome-reveal';

const about = AboutService.GetAboutDescription();
const About = () => {
    return (
        <section className='sectionAbout'>

            <Banner image={BannerImg} title='Un savoir-être ici et ailleurs' />
            <Fade cascade>
                {about.map((item, index) => (

                    <Collapse title={item.title} description={item.description} />

                ))
                }
            </Fade>
        </section>
    );
};

export default About;