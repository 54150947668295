import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Snowfall from 'react-snowfall'

const Banner = ({ image, title }) => {
    return (
        <div className="Banner">
            <Snowfall color="#FFF" snowflakeCount={40} />
            <img src={image} alt="Banner de haut page" />
            <div className="Banner-sombre"></div>
            <Fade delay={1000}>
                <h1>{title}</h1>
            </Fade>
        </div>
    );
};

export default Banner;